import { Toaster } from "react-hot-toast";

export const ToastManager = () => {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "border ",
        duration: 3000,
        style: {
          padding: "8px 16px 8px 16px",
          background: "#363636",
          color: "#fff",
        },

        // Default options for specific types
        success: {
          duration: 3000,

          theme: {
            primary: "green",
            secondary: "blue",
          },
        },
      }}
    />
  );
};
